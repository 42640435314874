<template>
  <div class="Jump"></div>
</template>

<script>
export default {
  mounted() {
    console.log(this.$route.query)
    this.$router.push(this.$route.query)
  }
}
</script>

<style></style>
